import React, { useState } from "react";
import logo from "../assets/Logob.png";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { useNavigate, Link } from "react-router-dom";
import "../styles/navbar.css";

export const Navbar = ({ user }) => {
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const handleLogout = (user) => {
    localStorage.removeItem("user");
    navigate("/login");
    window.location.reload(false);
  };

  return (
    <nav className="dashboard-nav">
      <Link to={"/" + user?.role_id?.role + "/dashboard"}>
        <div className="logo">
          <img src={logo} alt="qtopia logo" />
        </div>
      </Link>
      <span>{user?.role_id?.role === "curator" ? "Curator Mode" : "Admin Mode"}</span>
      <div className="profile" onClick={() => setShowProfile(!showProfile)}>
        <span>{user?.username}</span>
        <img className="user-img" src={user?.picture} alt="" />
        {!showProfile ? <AiFillCaretDown /> : <AiFillCaretUp />}
        {showProfile ? (
          <div className="profile-cont" style={{marginLeft: -25}}>
            <p>Profile settings</p>
            <div className="log-out" onClick={handleLogout}>
              <BiLogOutCircle /> Log Out
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};
