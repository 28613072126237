import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/admin/Dashboard';
import Quiz from './components/Quiz';
import Login from './components/Login';
import { AuthContext } from './Context/AuthContext';
import Signup from './components/Signup';
import NotFound from './components/NotFound';
import './App.css';

function App() {
  const { user } = useContext(AuthContext);
  return (
    <div className="app">
      <Router>
        <Routes>
          {user?.user && (
            <Route exact path="/curator/*" element={<Dashboard />} />
          )}
          {user?.user && (
            <Route exact path="/admin/*" element={<AdminDashboard />} />
          )}
          {<Route exact path="/questions" element={<Quiz />} />}
          <Route
            exact
            path="/login"
            element={
              user?.user?.role_id?.role === 'curator' ? (
                <Navigate to="/curator/dashboard" />
              ) : user?.user?.role_id?.role === 'manager' ? (
                <Navigate to="/manager/dashboard" />
              ) : user?.user?.role_id?.role === 'admin' ? (
                <Navigate to="/admin/dashboard" />
              ) : (
                <Login />
              )
            }
          />
          <Route exact path="/signUp" element={<Signup />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
