import React from "react";
import { GrAdd } from "react-icons/gr";
import { ImStatsDots } from "react-icons/im";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckAll } from "react-icons/bs";
import "../styles/curator.css";
import { Link } from "react-router-dom";
const options = [
  {
    opt: "View Stats",
    icon: <ImStatsDots />,
    path: "/curator/stats",
  },
  {
    opt: "Review Questions",
    icon: <AiOutlineCheck />,
    path: "/curator/review",
  },
  {
    opt: "View Reviewed Questions",
    icon: <BsCheckAll />,
    path: "/curator/reviewed",
  },
  // {
  //   opt: "view edited questions",
  //   icon: <MdOutlineModeEditOutline />,
  //   path: "/curator/edited",
  // },
];
export const Curator = ({ user }) => {
  return (
    <section className="curator-wrapper">
      <div className="curator-info">
        <p>Welcome</p>
        <h2>{user?.username}</h2>
      </div>
      <Link to="/curator/createquestion" className="create">
        <span>create new questions</span>
        <span>
          <GrAdd />
        </span>
      </Link>
      <div className="curator-options">
        {options.map((item, index) => {
          const { opt, icon, path } = item;
          return (
            <Link to={path} className="opt-list" key={index}>
              <span>{opt}</span>
              <span>{icon}</span>
            </Link>
          );
        })}
      </div>
    </section>
  );
};
