import { React } from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "../Navbar";
import Admin from "./Admin";
import LiveTournamentQuiz from "./LiveTournamentQuiz";
import NotFound from "../NotFound";

function Dashboard() {
  
  const userObj = JSON.parse(localStorage.getItem("user"));
  const user = userObj?.user;

  return (
    <>
      <Navbar user={user} />
      <Routes>
        <Route exact path="/dashboard" element={<Admin />} />
        <Route exact path="/live-quiz" element={<LiveTournamentQuiz />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Dashboard;
