import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { USER_SERVER } from '../Config';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  Switch,
} from '@mui/material';
import { Add, Upload } from '@mui/icons-material';
import makeRequest from '../helper/request';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  open,
  setOpen,
  handleOpen,
  handleClose,
  quiz,
}) {
  // console.log(quiz);
  const navigate = useNavigate();
  console.log(quiz);
  const [alertMessage, setAlertMessage] = useState(null);
  const [edit, setEdit] = useState({ ...quiz, topic: quiz?.topic?.topic });
  const [photo, setPhoto] = useState(null);
  const [topics, setTopics] = useState();
  const [questionTypes, setQuestionTypes] = useState();
  const [imgFormData, setImgFormData] = useState(null);

  useEffect(() => {
    fetch_topics();
    fetch_question_types();
  }, []);

  const fetch_question_types = async () => {
    const { data } = await makeRequest({
      method: 'get',
      endpoint: `/question_types`,
    });
    console.log(data);
    setQuestionTypes(data);
  };
  const fetch_topics = async () => {
    const { data } = await makeRequest({
      method: 'get',
      endpoint: `/topics`,
    });
    console.log(data);
    setTopics(data);
  };
  const onDrop = async (files) => {
    let formData = new FormData();
    console.log(files);
    formData.append('imageVideo', files[0]);
    setPhoto(files[0].path);
    setImgFormData(formData);
  };
  const updateAns = (i) => {
    console.log(i);
    let arrNew = edit.question_choices.slice();
    console.log(arrNew);
    arrNew[i]['is_correct'] = !arrNew[i]['is_correct'];
    setEdit({ ...edit, question_choices: arrNew });
  };

  const validate = (formData) => {
    console.log(formData);
    let err = null,
      no_ans = false;
    if (!formData.questionType || formData.questionType === 'none')
      err = 'Question Type';
    else if (!formData.difficultyLevel || formData.difficultyLevel === 'none')
      err = 'Difficulty Level';
    else if (!formData.question) err = 'Question';
    else if (!formData.topic || formData.topic === 'none') err = 'Topic';

    let options = formData.question_choices;
    console.log(options);
    for (var i = 0; i < options.length; i++) {
      no_ans = no_ans || options[i].is_correct;
      if (options[i].choice.length === 0) {
        err = 'Value of each option';
        break;
      }
    }
    console.log(options);

    console.log(no_ans);
    if (!no_ans) err = 'At least one correct answer';
    if (formData.time_to_answer < 10 || formData.time_to_answer > 100)
      err = 'Time must be in between 10s to 100s';

    if (err) {
      setAlertMessage(err);
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      return false;
    } else return true;
  };

  const updateOpts = (e, i) => {
    let arrNew = edit.question_choices.slice();
    console.log(arrNew);
    arrNew[i].choice = e.target.value;
    setEdit({ ...edit, question_choices: arrNew });
  };

  const editQuiz = async (e, attribute) => {
    if (e.target.name === 'options') {
      const a = edit.options;
      a[attribute] = e.target.value;
      setEdit((prev) => {
        return { ...prev, choice: a };
      });
    } else {
      setEdit((prev) => {
        return { ...prev, [attribute]: e.target.value };
      });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (photo !== null) {
      edit.imageVideo = photo;
    }

    if (validate(edit)) {
      console.log(edit);
      const newFormData = new FormData();

      const questionChoices = edit?.question_choices?.map((obj) => {
        delete obj._id;
        return obj;
      });
      newFormData.append('question', edit?.question);
      newFormData.append('questionType', edit?.questionType?.questionType);
      newFormData.append('topic', edit?.topic);
      newFormData.append('difficultyLevel', edit?.difficultyLevel);
      newFormData.append('choices', JSON.stringify(questionChoices));
      if (edit.remarks) {
        newFormData.append('remarks', edit.remarks);
      }
      if (edit.imageVideoSource) {
        newFormData.append('imageVideoSource',edit?.imageVideoSource);
      }
      if (imgFormData) {
        for (const [key, value] of imgFormData.entries()) {
          newFormData.append(key, value);
        }
      }
      if (edit.time_to_answer) {
        newFormData.append('time_to_answer', edit?.time_to_answer);
      }

      const res = await makeRequest({
        method:'patch',
        endpoint:`/questions/${edit?._id}`,
        data:newFormData
    })
      if (res.status === 200) {
        navigate('/curator/dashboard');
      }
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        sx={{ overflow: 'scroll' }}
      >
        <Box sx={style} style={{ display: 'flex', flexDirection: 'column' }}>
          {alertMessage ? (
            <Alert severity="error">{alertMessage} is required!!</Alert>
          ) : null}
          <div className="review-ques-wrapper">
            <div className="review-box">
              <div className="review-left" style={{ width: '60%' }}>
                <Grid container sx={{ flexDirection: 'row', gap: 2 }}>
                  <FormControl>
                    <Typography fontWeight={'bold'}>Difficulty *</Typography>
                    <Select
                      value={edit?.difficultyLevel}
                      onChange={(e) => editQuiz(e, 'difficultyLevel')}
                      name="difficulty"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={'easy'}>Easy</MenuItem>
                      <MenuItem value={'medium'}>Medium</MenuItem>
                      <MenuItem value={'hard'}>Hard</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{}}>
                    <Typography fontWeight={'bold'}>Topic *</Typography>
                    <Select
                      value={edit?.topic}
                      onChange={(e) => editQuiz(e, 'topic')}
                      name="topic"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {topics?.topics?.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.topic}>
                            {item.topic}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid container sx={{ flexDirection: 'column', gap: 1 }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Typography fontWeight={'bold'}>Question*</Typography>
                    <Grid
                      container
                      sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                      <Typography fontWeight={'bold'} htmlFor="lmt-life">
                        Limited life question
                      </Typography>

                      <Switch
                        checked={edit?.timeBased}
                        onChange={(e) => {
                          setEdit((prev) => ({
                            ...prev,
                            timeBased: e.target.checked,
                          }));
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className="ques">
                    <TextareaAutosize
                      name="question"
                      placeholder="Type your question here"
                      value={edit?.question}
                      onChange={(e) => editQuiz(e, 'question')}
                      minRows={5}
                      style={{ padding: '20px', width: '100%' }}
                    />
                  </div>
                  <Box sx={{ m: 1 }}>
                    {!edit?.imageVideo ? null : (
                      <img
                        src={edit?.imageVideo}
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'contain',
                        }}
                        alt="img"
                      />
                    )}
                  </Box>

                  <Typography fontWeight={'bold'}>Image drop *</Typography>
                  <Grid container sx={{ gap: 0.5, alignItems: 'center' }}>
                    <FormControl sx={{ width: '70%' }}>
                      <TextField
                        name="img-url"
                        type="input"
                        placeholder="Add Source ( URL / name )"
                        value={edit?.imageVideoSource}
                        onChange={(e) => editQuiz(e, 'imageVideoSource')}
                      />
                    </FormControl>
                    <Dropzone onDrop={onDrop} className="choose-file">
                      {({ getRootProps, getInputProps }) => (
                        <Button
                          variant="contained"
                          {...getRootProps()}
                          sx={{ width: '28%' }}
                        >
                          <input {...getInputProps()} />
                          <Upload
                            style={{ color: 'white', fontSize: '30px' }}
                          />
                          Upload
                        </Button>
                      )}
                    </Dropzone>
                  </Grid>

                  <Box sx={{ mt: 2 }}>
                    <p>Remarks</p>
                    <div className="remark">
                      {' '}
                      <input
                        name="remarks"
                        type="text"
                        value={edit?.remarks}
                        style={{ width: '100%', height: '30px' }}
                        onChange={(e) => editQuiz(e, 'remarks')}
                      />
                    </div>
                  </Box>
                </Grid>
              </div>

              <div className="review-right">
                <div className="option-cont">
                  <label>
                    {edit?.questionType?.questionType.charAt(0).toUpperCase() +
                      edit?.questionType?.questionType.slice(1)}
                  </label>

                  <div className="options">
                    {edit?.question_choices.map((item, index) => {
                      const { choice, is_correct } = item;
                      return (
                        <TextField
                          value={choice}
                          key={index}
                          onChange={(e) => updateOpts(e, index)}
                          InputProps={{
                            endAdornment: (
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: '#8CC295',
                                  textTransform: 'none',
                                  ':hover': { bgcolor: '#8CC295' },
                                  height: '100%',
                                  width: '40%',
                                }}
                                onClick={() => updateAns(index)}
                              >
                                Correct Answer
                              </Button>
                            ),

                            style: {
                              border:
                                is_correct === true
                                  ? 'solid green 3px'
                                  : 'solid black 1px',
                              paddingRight: 0,
                            },
                          }}
                          sx={{ paddingRight: 0 }}
                        />
                      );
                    })}{' '}
                    <Button
                      onClick={() => {
                        setEdit({
                          ...edit,
                          question_choices: [
                            ...edit.question_choices,
                            { choice: '', is_correct: false },
                          ],
                        });
                      }}
                      variant="contained"
                      sx={{ width: '100%' }}
                    >
                      <Add />
                    </Button>
                    <FormControl>
                      <Typography fontWeight={'bold'}>
                        Time To Answer
                      </Typography>
                      <TextField
                        placeholder="Enter number between 10 to 100"
                        name="timeToAnswer"
                        type="number"
                        value={edit?.time_to_answer}
                        onChange={(e) => editQuiz(e, 'time_to_answer')}
                        InputProps={{ inputProps: { min: 10, max: 100 } }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            <Button
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              sx={{ width: '250px', m: 'auto' }}
            >
              Submit
            </Button>
          </div>

          {/* <label>
            timeBased
            <input
              name="timeBased"
              type="checkbox"
              checked={quiz.timeBased}
              placeholder={quiz.timeBased}
              onChange={(e) => editQuiz(e, "updatedTimeBased")}
            />
          </label> */}
        </Box>
      </Modal>
    </div>
  );
}
