import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Upload, Add } from '@mui/icons-material';
import '../styles/createQues.css';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { USER_SERVER } from '../Config';
import { Box } from '@mui/system';
import makeRequest from '../helper/request';

export const CuratorCreateques = () => {
  const navigate = useNavigate();
  const [quesType, setQuesType] = useState('multiple');
  const [topics, setTopics] = useState();
  const [questionTypes, setQuestionTypes] = useState();
  const [photoPath, setPhotoPath] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [photo, setPhoto] = useState('');
  const [imgFormData, setImgFormData] = useState(null);
  const [opt_arr, setOptArr] = useState([
    { opt: '', key: 0 },
    { opt: '', key: 0 },
    { opt: '', key: 0 },
    { opt: '', key: 0 },
  ]);
  const fetch_question_types = async () => {
    const { data } = await makeRequest({
      method: 'get',
      endpoint: `/question_types`,
    });
    console.log(data);
    setQuestionTypes(data);
  };
  const fetch_topics = async () => {
    const { data } = await makeRequest({
      method: 'get',
      endpoint: `/topics`,
    });
    console.log(data);
    setTopics(data);
  };
  useEffect(() => {
    try {
      fetch_topics();
      fetch_question_types();
    } catch (error) {
      console.log(error);
      //setLoading(false);
    }
  }, []);

  const onDrop = async (files) => {
    console.log(files);
    setPhotoPath(files[0].path);
    let formData = new FormData();

    formData.append('imageVideo', files[0]);
    setImgFormData(formData);
  };

  const updateAns = (i) => {
    let arrNew = opt_arr.slice();
    arrNew.map((item, index) => {
      if (index === i) item['key'] = 1;
      return item;
    });
    setOptArr(arrNew);
  };

  const updateOpts = (e, i) => {
    let arrNew = opt_arr.slice();
    arrNew[i]['opt'] = e.target.value;
    setOptArr(arrNew);
  };

  const validate = (formData) => {
    console.log(formData);
    let err = null,
      no_ans = false;
    if (!formData.questionType || formData.questionType === 'none')
      err = 'Question Type';
    else if (!formData.difficultyLevel || formData.difficultyLevel === 'none')
      err = 'Difficulty Level';
    else if (!formData.question) err = 'Question';
    else if (!formData.topic || formData.topic === 'none') err = 'Topic';

    let options = formData.choices;
    console.log(options);
    for (var i = 0; i < options.length; i++) {
      no_ans = no_ans || options[i].is_correct;
      if (options[i].choice.length === 0) {
        err = 'Value of each option';
        break;
      }
    }
    console.log(options);

    if (!no_ans) err = 'At least one correct answer';
    if(formData.timeToAnswer < 10 || formData.timeToAnswer > 100){
      err="Time should be greater than equal to 10 and less than 100"
    }

    if (err) {
      setAlertMessage(err);
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      return false;
    } else return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('imgFormData', imgFormData);
    try {
      let arr = [];

      opt_arr.forEach((item, index) => {
        arr.push({
          choice: item.opt,
          is_correct: item.key === 1 ? true : false,
        });
      });
      const data = new FormData(event.currentTarget);
      const questionType = quesType;
      const quesData = {
        question: data.get('question').trim(),
        questionType: quesType,
        imageVideoSource: data.get('img-url'),

        topic: data.get('topic'),
        imageVideo: imgFormData,
        difficultyLevel: data.get('difficulty'),
        remarks: data.get('remarks'),
        time_to_answer:data.get('timeToAnswer')
      };
      if (questionType === 'one word') {
        quesData.choices = [
          { choice: data.get('one-word-ans'), is_correct: true },
        ];
      } else if (questionType === 'multiple') {
        quesData.choices = arr;
      }
      if (data.get('lmt-life') === null) {
        quesData.timeBased = false;
      } else {
        quesData.timeBased = true;
      }
      console.log(quesData);
      if (validate(quesData)) {
        console.log(quesData);
        const newFormData = new FormData();
        newFormData.append('question', quesData?.question);
        newFormData.append('questionType', quesData?.questionType);
        newFormData.append('topic', quesData?.topic);
        newFormData.append('difficultyLevel', quesData?.difficultyLevel);
        newFormData.append('choices', JSON.stringify(quesData?.choices));
        if (quesData.remarks) {
          newFormData.append('remarks', quesData.remarks);
        }
        if (quesData.imageVideoSource) {
          newFormData.append('imageVideoSource', quesData.imageVideoSource);
        }
        if (imgFormData) {
          for (const [key, value] of imgFormData.entries()) {
            newFormData.append(key, value);
          }
        }
        if (quesData.time_to_answer) {
          newFormData.append('time_to_answer', quesData.time_to_answer);
        }
        const response = await makeRequest({
          method:'post',
          endpoint:'/questions',
          data:newFormData,
        });
        if (response.status === 200) {
          navigate('/curator/dashboard');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let a = document.querySelectorAll('.option-inp');
    a.forEach((item, index) => {
      item.value = opt_arr[index].opt;
    });
  }, [opt_arr]);
  return (
    <Box sx={{ px: '8%' }}>
      {alertMessage ? (
        <Alert severity="error">{alertMessage} is required!!</Alert>
      ) : null}

      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            container
            sx={{ flexDirection: 'column', gap: 1, p: 2 }}
          >
            <Grid container>
              <FormControl sx={{ m: 1 }}>
                <Typography fontWeight={'bold'}>Difficulty *</Typography>
                <Select
                  // placeholder="Select Difficulty"
                  defaultValue={'none'}
                  name="difficulty"
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="none" disabled>
                    <em>Select Difficulty</em>
                  </MenuItem>
                  <MenuItem value={'easy'}>Easy</MenuItem>
                  <MenuItem value={'medium'}>Medium</MenuItem>
                  <MenuItem value={'hard'}>Hard</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, width: '40%' }}>
                <Typography fontWeight={'bold'}>Topic *</Typography>
                <Select
                  defaultValue={'none'}
                  name="topic"
                  inputProps={{ 'aria-label': 'Without label' }}
                  IconComponent={() => (
                    <Box
                      sx={{
                        bgcolor: '#D9D9D9',
                        height: '100%',
                        width: '30%',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Search sx={{ color: '#111', margin: 'auto' }} />
                    </Box>
                  )}
                >
                  <MenuItem value="none" disabled>
                    <em>Select Topic</em>
                  </MenuItem>
                  {topics?.topics.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.topic}>
                        {item.topic}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <FormControl>
              <Grid
                container
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                }}
              >
                <Typography fontWeight={'bold'}>Question*</Typography>
                <Grid
                  container
                  sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Typography fontWeight={'bold'} htmlFor="lmt-life">
                    Limited life question
                  </Typography>

                  <Switch name="lmt-life" id="lmt-life" />
                </Grid>
              </Grid>
              <TextareaAutosize
                name="question"
                placeholder="Type your question here"
                minRows={10}
                style={{ padding: '20px' }}
              />
            </FormControl>
            {quesType === 'multiple' ? (
              <FormControl sx={{ width: '100%' }}>
                <Typography fontWeight={'bold'}>Remarks</Typography>
                <TextareaAutosize
                  name="remarks"
                  placeholder="Add remarks for the question"
                  minRows={10}
                  style={{ padding: '20px' }}
                />
              </FormControl>
            ) : null}

            <Grid container>
              <Typography fontWeight={'bold'}>Image</Typography>
              <Grid container sx={{ gap: 1, alignItems: 'center' }}>
                <TextField
                  name="img-url"
                  type="input"
                  placeholder="Add Source ( URL / name )"
                  style={{ width: '70%' }}
                />

                <Dropzone onDrop={onDrop} className="choose-file">
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      variant="contained"
                      {...getRootProps()}
                      style={{ width: '28%' }}
                    >
                      <input {...getInputProps()} />
                      <Upload
                        style={{
                          color: 'white',
                          fontSize: '30px',
                        }}
                      />
                      Upload
                    </Button>
                  )}
                </Dropzone>
                {photoPath}
              </Grid>
              {photo ? (
                <Box>
                  <img
                    style={{ border: '2px solid black' }}
                    src={photo}
                    alt="no-imgage-available"
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            container
            sx={{ flexDirection: 'column', gap: 1, p: 2 }}
          >
            <FormControl sx={{ width: '100%', p: 0, m: 0 }}>
              <Typography fontWeight={'bold'}>Type *</Typography>
              <Select
                defaultValue={'none'}
                name="type"
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setQuesType(e.target.value);
                }}
                value={quesType}
              >
                <MenuItem value="none" disabled>
                  <em>Select Type</em>
                </MenuItem>
                {questionTypes?.questionTypes.map((item, key) => {
                  return (
                    <MenuItem value={item.questionType} key={key}>
                      {item.questionType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {quesType === 'multiple' ? (
              <Grid>
                <Typography fontWeight={'bold'}>Multiple Choice</Typography>
                <Grid container sx={{ flexDirection: 'column', gap: 0.5 }}>
                  {opt_arr.map((item, index) => {
                    const { opt, key } = item;
                    return (
                      <TextField
                        value={opt}
                        key={index}
                        onChange={(e) => updateOpts(e, index)}
                        InputProps={{
                          endAdornment: (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: '#8CC295',
                                textTransform: 'none',
                                ':hover': { bgcolor: '#8CC295' },
                              }}
                              onClick={() => updateAns(index)}
                            >
                              Correct Answer
                            </Button>
                          ),

                          style: {
                            border:
                              key === 1 ? 'solid green 3px' : 'solid black 1px',
                            paddingRight: 0,
                          },
                        }}
                        sx={{ paddingRight: 0 }}
                      />
                    );
                  })}
                </Grid>
                <Button
                  onClick={() => {
                    setOptArr((opt_arr) => [...opt_arr, { opt: '', key: 0 }]);
                  }}
                  variant="contained"
                  sx={{ width: '100%', marginTop: 3 }}
                >
                  <Add />
                </Button>
              </Grid>
            ) : (
              <Grid container sx={{ gap: 0.5, flexDirection: 'column' }}>
                <FormControl sx={{ width: '100%' }}>
                  <Typography fontWeight={'bold'}>Answer</Typography>
                  <TextField placeholder="Enter answer" name="one-word-ans" />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <Typography fontWeight={'bold'}>Remarks</Typography>
                  <TextareaAutosize
                    name="remarks"
                    placeholder="Add remarks for the question"
                    minRows={10}
                    style={{ padding: '20px' }}
                  />
                </FormControl>
              </Grid>
            )}
            <FormControl>
              <Typography fontWeight={'bold'}>Time To Answer</Typography>
              <TextField
                placeholder="Enter number between 10 to 100"
                name='timeToAnswer'
                type="number"
                InputProps={{ inputProps: { min: 10, max: 100 } }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: '300px',
            m: 'auto',
            display: 'block',
            marginBottom: 10,
            marginTop: 10,
            padding: 2,
            borderRadius: 10,
            backgroundColor: '#00008B',
          }}
        >
          Save
        </Button>
      </form>
    </Box>
  );
};
