import React, { useState, useContext, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { configureAbly, useChannel } from '@ably-labs/react-hooks';
import { AuthContext } from '../../Context/AuthContext';

const globalQuizChName = 'main-quiz-thread';

const LiveTournamentQuiz = () => {
  const [quizId, setQuizId] = useState('');
  const [isRoomReady, setIsRoomReady] = useState(false);
  const [didHostStartGame, setDidHostStartGame] = useState(false);
  const [warmupTimer, setWarmupTimer] = useState(1);
  const [timer, setTimer] = useState(1);
  const [questionTimer, setQuestionTimer] = useState(1);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [newQuestionNumber, setNewQuestionNumber] = useState(0);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const { user } = useContext(AuthContext);
  const [isWarmTimerEnded, setIsWarmTimerEnded] = useState(false);
  const [isButtonDisabled,setIsButtonDisabled] = useState(false);
  
  configureAbly({
    authUrl: `https://api.qtopia.in/api/v2/ably/auth?clientId=${user.user._id}`,
  });

  const [globalQuizCh, realtime] = useChannel(
    globalQuizChName,
    'no-event',
    () => {}
  );

  const subscribeToQuizRoomChEvents = (quizRoomChannel) => {
    quizRoomChannel.subscribe('new-player', (msg) => {
      setTotalPlayers(totalPlayers + 1);
    });

    quizRoomChannel.subscribe('warmup-timer', (msg) => {
      setWarmupTimer(msg.data.countDownSec);
    });

    quizRoomChannel.subscribe('start-quiz-timer', (msg) => {
      setDidHostStartGame(true);
      setTimer(msg.data.countDownSec);
    });

    quizRoomChannel.subscribe('new-question', (msg) => {
      setNewQuestionNumber(msg.data.questionNumber);
      setIsLastQuestion(msg.data.isLastQuestion);
    });

    quizRoomChannel.subscribe('question-timer', (msg) => {
      setQuestionTimer(msg.data.countDownSec);
      if (questionTimer < 0) {
        setQuestionTimer(30);
      }
    });

    quizRoomChannel.subscribe('quiz-ending', () => {
      window.alert('The quiz has completed successfully');
      setIsRoomReady(false);
      setQuizId('');
      setDidHostStartGame('');
      setTotalPlayers(0);
      setNewQuestionNumber(0);
      setIsLastQuestion(false);
      setIsButtonDisabled(false);
    });
  };

  const enterGameRoomAndSubscribeToEvents = () => {
    const quizRoomChannel = realtime.channels.get(`${quizId}:primary`);
    const hostAdminChannel = realtime.channels.get(`${quizId}:host`);

    quizRoomChannel.presence.enter({
      isHost: true,
    });

    subscribeToQuizRoomChEvents(quizRoomChannel);

    hostAdminChannel.publish('start-warmup', { start: true });
  };

  const createQuizRoom = async () => {
    if(quizId){
      setIsButtonDisabled(true);
    }
    console.log('Creating new room for quiz_id - ', quizId);

    const quizRoomChannel = realtime.channels.get(`${quizId}:primary`);

    quizRoomChannel.subscribe('thread-ready', (message) => {
      console.log('The quiz room is ready', message.data.start);
      setIsRoomReady(message.data.start);
      globalQuizCh.detach();
      enterGameRoomAndSubscribeToEvents();
    });

    globalQuizCh.presence.enter({
      roomCode: quizId,
    });
  };

  const startQuiz = () => {
    const hostAdminChannel = realtime.channels.get(`${quizId}:host`);
    hostAdminChannel.publish('start-quiz', {
      start: true,
    });
  };

  const showNextQuestion = () => {
    const hostAdminChannel = realtime.channels.get(`${quizId}:host`);
    hostAdminChannel.publish('next-question', {
      prevQIndex: newQuestionNumber - 1,
    });
  };
  useEffect(() => {
    if (warmupTimer === 0 && !isWarmTimerEnded) {
      startQuiz();
      setIsWarmTimerEnded(true);
    }
    if (!isLastQuestion && questionTimer === 0) {
      showNextQuestion();
    }
  }, [warmupTimer, questionTimer]);

  return (
    <div>
      {!isRoomReady ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            '& > *': {
              margin: 1.5,
            },
          }}
        >
          <TextField
            label="Quiz ID"
            value={quizId}
            onChange={(event) => setQuizId(event.target.value)}
          />
          <Button disabled={isButtonDisabled} variant="contained" type="submit" onClick={createQuizRoom}>
            Create Quiz Room
          </Button>
        </Box>
      ) : !didHostStartGame ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            '& > *': {
              margin: 1.5,
            },
          }}
        >
          <h4> {totalPlayers} players have joined till now</h4>
          {warmupTimer > 1 ? (
            <>
              <h3>Warmup Section is being shown</h3>
              <h1 className="display-3">{warmupTimer}</h1>
            </>
          ) : (
            <Button
              disabled
              variant="contained"
              type="submit"
              onClick={startQuiz}
            >
              Start the Quiz
            </Button>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            '& > *': {
              margin: 1.5,
            },
          }}
        >
          {timer > 1 ? (
            <>
              <h3>Your quiz starts in: </h3>
              <h1 className="display-3">{timer}</h1>
            </>
          ) : (
            <div>
              <div className="card-header">Question {newQuestionNumber}</div>
              <h3>{questionTimer}</h3>
              {!isLastQuestion && (
                <Button disabled variant="contained" onClick={showNextQuestion}>
                  Next Question
                </Button>
              )}
            </div>
          )}
        </Box>
      )}
    </div>
  );
};

export default LiveTournamentQuiz;
