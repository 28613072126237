import React, { useEffect, useState } from 'react';
import '../styles/curator_review.css';
import Quizes from './Quizes';
import makeRequest from '../helper/request';

export const CuratorReview = () => {
  const [quiz, setquiz] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idx, setIdx] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    try {
      fetch_ques();
    } catch (error) {
      setLoading(false);
    }
  }, []);


  const fetch_ques = async() => {
    setLoading(true);
    const {data}= await  makeRequest({
      method: 'get',
      endpoint: `/questions`,
    })
    console.log(data.questions);
    setquiz(data?.questions);
    setLoading(false);
  };

  const handleReview = async () => {
    await makeRequest({
      method: 'patch',
      endpoint: `/questions/${quiz[idx]._id}/review`,
    })
    setIdx((idx) => idx + 1);
  };
  console.log(quiz[0]?.topic?.topic.toUpperCase());
  return (
    <Quizes
      loading={loading}
      setOpen={setOpen}
      idx={idx}
      setIdx={setIdx}
      quiz={quiz}
      review={true}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleReview={handleReview}
    />
  );
};
