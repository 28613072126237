import React from "react";
import { GrAdd } from "react-icons/gr";
import "../../styles/curator.css";
import { Link } from "react-router-dom";

export default function Admin ({ user }) {
  return (
    <section className="curator-wrapper">
      <div className="curator-info">
        <p>Welcome</p>
        <h2>{}</h2>
      </div>
      <Link to="/admin/live-quiz" className="create">
        <span>Host Live Tournament</span>
        <span>
          <GrAdd />
        </span>
      </Link>
    </section>
  );
};
