import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';
import { loginCall } from '../apiCalls';
import logo from '../assets/Logo.png';
import '../styles/login.css';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (user?.user?.role_id?.role === 'curator') {
      navigate('/curator/dashboard');
    } else if (user?.user?.role_id?.role === 'manager') {
      navigate('/manager/dashboard');
    } else if (user?.user?.role_id?.role === 'admin') {
      navigate('/admin/dashboard');
    }
  }, [user]);

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        '447559999794-6ojvvaps5rm15d52pdhuun2um15jo25i.apps.googleusercontent.com',
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      size: 'large',
    });
    google.accounts.id.prompt();
  }, []);

  function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    loginCall(
      {
        email: userObject?.email,
        username: userObject?.name,
        picture: userObject?.picture,
      },
      dispatch
    );
  }
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const Data = new FormData(e.currentTarget);
  //   const userData = {
  //     email: Data.get("email"),
  //     password: Data.get("password"),
  //     role: Data.get("role"),
  //   };
  // loginCall(
  //   {
  //     email: userData.email,
  //     password: userData.password,
  //     role: userData.role,
  //   },
  //   dispatch
  // );
  // };
  return (
    <div className="login-wrapper">
      <div className="header">
        <img src={logo} alt="qtopia logo" />
      </div>
      <div className="login">
        <div className="login-options">
          <p style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
            Login into Qtopia via
          </p>
          <div id="signInDiv"></div>
        </div>
      </div>
    </div>
  );
}

export default Login;
