import React, { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import logo from "../assets/Logo.png";
import qtopia from "../assets/Qtopia.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import "../styles/login.css";
function Signup() {
  const { user } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const Data = new FormData(e.currentTarget);
    const userData = {
      email: Data.get("email"),
      password: Data.get("password"),
      date: Data.get("date"),
    };
  };
  return (
    <div className="login-wrapper">
      <div className="header">
        <img src={logo} alt="qtopia logo" />
      </div>
      <div className="login">
        <div className="login-options">
          <p style={{ fontWeight: "bold" }}>
            Sign Up to join the Quizzing Revolution Now!
          </p>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#25D366", mb: 1.5, p: 1.5 }}
          >
            sign Up via whatsup Message
          </Button>
        </div>
        <div className="or-line">
          <hr />
          <span>OR</span>
        </div>
        <div className="login-form">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Enter you email"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="createpassword"
                      label="Create Password"
                      type="password"
                      id="createpassword"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmpassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmpassword"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="date"
                      type="date"
                      id="date"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, p: 2, borderRadius: "46px" }}
                >
                  SIGN UP
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <footer className="login-footer">
        <p> @ {new Date().getFullYear()} </p> |{" "}
        <img src={qtopia} alt="qtopia name" />
      </footer>
    </div>
  );
}

export default Signup;
