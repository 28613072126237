import axios from 'axios';
import { USER_SERVER } from '../Config';

async function makeRequest({
  endpoint,
  data,
  method,
  setHeader,
}) {
  let url = `${USER_SERVER}${endpoint}`;
  try {
    const response = await axios({
      method,
      url,
      data,
      setHeader,
    });
    console.log(response.data, 'response');
    return response;
  } catch (error) {
    // Handle error appropriately
    if (error?.response?.status === 401) {
      localStorage.removeItem('user');
       window.location.reload();
    }
    console.error('Error making the request:', error);
    throw error;
  }
}

export default makeRequest;
