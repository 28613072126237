import React, { useState, useEffect } from "react";
import { USER_SERVER } from "../Config";
import Quizes from "./Quizes";
import makeRequest from "../helper/request";
function CuratorReviewed() {
  const [idx, setIdx] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      const fetch = async () => {
        setLoading(true);
        const res = await makeRequest({
          method: 'get',
          endpoint: `/questions/?status=reviewer`,
        })
        console.log(res);
        setQuiz(res.data);
        setLoading(false);
      };
      fetch();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div>
      {quiz?.length > 0 && <Quizes idx={idx} setIdx={setIdx} quiz={quiz} />}
    </div>
  );
}

export default CuratorReviewed;
