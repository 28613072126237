import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, CssBaseline } from "@mui/material";
import { useParams } from "react-router-dom";
import { USER_SERVER } from "../Config";
import makeRequest from "../helper/request";

function Quiz() {
  const [quizzes, setQuizzes] = useState([]);
  const [selected, setSelected] = useState(-1);
  const routeParams = useParams();
  console.log(routeParams);

  useEffect(() => {
    const Fetch = async () => {
      const res= await makeRequest({
        method:'get',
        endpoint:`/allquestions`
    })
        var gett = res.data.quizzes;
        var f = gett.slice(0, 5);
        setQuizzes(f);
        console.log(res.data.quizzes);
      
    };
    Fetch();
  }, []);
  const editQuestion = (val) => {
    setSelected(val);
  };

  const handleSubmit = async (idx) => {
    await axios
      .post(`${USER_SERVER}/allquestions/${quizzes[idx]._id}`, {
        options: quizzes[idx].updatedOptions,
        updated_ques: quizzes[idx].updatedQuestion,
      })
      .then((res) => {
        var gett = res.data.quizzes;
        var f = gett.slice(0, 5);
        setQuizzes(f);
        console.log(res.data.quizzes);
        window.location.reload(true);
      });
  };
  const editOptions = (e, idx) => {
    console.log(e.target.value, e.target.name);
    const updateQuiz = quizzes;

    if (e.target.name === "updatedQuestion") {
      updateQuiz[idx].updatedQuestion = e.target.value;
    } else {
      if (quizzes[idx].updatedOptions.length === 0) {
        updateQuiz[idx].updatedOptions = new Array(4);
      } else {
        updateQuiz[idx].updatedOptions = quizzes[idx].updatedOptions;
      }

      updateQuiz[idx].updatedOptions[Number(e.target.name)] = e.target.value;
    }
    setQuizzes(updateQuiz);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{ marginTop: "80px" }}>
          {quizzes.length > 0 &&
            quizzes.map((item, idx) => {
              return (
                <div key={idx}>
                  {idx === selected ? (
                    <input
                      name="updatedQuestion"
                      type="text"
                      placeholder={item.questions}
                      onChange={(e) => editOptions(e, idx)}
                      style={{ width: "50%" }}
                    />
                  ) : (
                    <h3>{item.questions}</h3>
                  )}
                  <br />

                  <input
                    name="0"
                    type="text"
                    placeholder={item.options[0]}
                    onChange={(e) => editOptions(e, idx)}
                    disabled={idx !== selected ? "disabled" : ""}
                  />
                  <input
                    name="1"
                    type="text"
                    placeholder={item.options[1]}
                    onChange={(e) => editOptions(e, idx)}
                    disabled={idx !== selected ? "disabled" : ""}
                  />
                  <input
                    name="2"
                    type="text"
                    placeholder={item.options[2]}
                    onChange={(e) => editOptions(e, idx)}
                    disabled={idx !== selected ? "disabled" : ""}
                  />
                  <input
                    name="3"
                    type="text"
                    placeholder={item.options[3]}
                    onChange={(e) => editOptions(e, idx)}
                    disabled={idx !== selected ? "disabled" : ""}
                  />
                  {idx === selected ? (
                    <button
                      onClick={() => {
                        handleSubmit(idx);
                      }}
                    >
                      {" "}
                      Submit
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        editQuestion(idx);
                      }}
                    >
                      {" "}
                      Edit Question
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </Box>
    </Box>
  );
}

export default Quiz;
