import React from "react";
import EditModal from "./EditModal";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import "../styles/curator_review.css";
import { Check } from "@mui/icons-material";

function Quizes({
  quiz,
  idx,
  setIdx,
  open,
  handleOpen,
  handleClose,
  setOpen,
  handleReview,
  loading,
  review,
  topics,
  questionTypes,
}) {
  console.log(quiz);
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "80vh",
        justifyContent: "space-between",
        flexWrap: "nowrap",
      }}
    >
      {loading ? (
        <CircularProgress sx={{ mx: "auto" }} />
      ) : (
        <>
          {idx + 1 <= quiz.length ? (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  sx={{ flexDirection: "column", gap: 1, p: 2 }}
                >
                  <Grid
                    container
                    sx={{ flexDirection: "column", gap: 1, p: 2 }}
                  >
                    <Typography fontWeight={"bold"} variant="h5">
                      Question
                    </Typography>

                    <div className="ques">{quiz[idx]?.question}</div>
                    <Box>
                      {quiz[idx]?.imageVideo === null ? (
                        <div
                          style={{
                            height: "200px",
                            width: "200px",
                            backgroundColor: "grey",
                          }}
                        ></div>
                      ) : (
                        <img
                          src={quiz[idx]?.imageVideo}
                          alt="no-imgage-available"
                          style={{
                            padding: "0.5rem 0",
                            height: "300px",
                            width: "400px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Box>
                    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
                      <Typography fontWeight={"bold"} variant="h5">
                        Remarks
                      </Typography>
                      <Typography variant="body1">
                        {quiz[idx]?.remarks}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  sx={{ flexDirection: "column", gap: 2, p: 2 }}
                >
                  <Grid container sx={{ alignItems: "center", gap: 1 }}>
                    {review ? (
                      <>
                        <Button onClick={handleOpen} sx={{ height: "30px" }}>
                          Edit
                        </Button>

                        <Button
                          sx={{ backgroundColor: "#004AAD" }}
                          variant="contained"
                          onClick={handleReview}
                        >
                          Mark Done
                        </Button>
                      </>
                    ) : null}
                    <Button
                      onClick={() => {
                        setIdx((idx) => idx + 1);
                      }}
                      sx={{ borderColor: "#004AAD", color: "#004AAD" }}
                      variant="outlined"
                    >
                      {review ? "Skip" : "Next"}
                    </Button>
                  </Grid>

                  <Grid container sx={{ flexDirection: "column", gap: 1 }}>
                    <Typography variant="body1" fontWeight={"bold"}>
                      {quiz[idx]?.questionType?.questionType
                        .charAt(0)
                        .toUpperCase() +
                        quiz[idx]?.questionType?.questionType.slice(1)}
                    </Typography>

                    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
                      {quiz[idx]?.question_choices?.map((opt, index) => {
                        console.log(opt);
                        return (
                          <Grid
                            container
                            key={index}
                            sx={{
                              border: "solid 1px black",
                              p: 2,
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ width: "80%" }}>{opt?.choice}</Box>
                            {opt?.is_correct === true ? (
                              <Check color="success" />
                            ) : null}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  justifyContent: "space-evenly",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <div className="ft-info">
                  <p>Limited Life Questions : </p>
                  <h3> {quiz[idx]?.timeBased.toString().toUpperCase()}</h3>
                </div>
                <div className="ft-info">
                  <p>Difficulty : </p>
                  <h3>{quiz[idx]?.difficultyLevel?.toUpperCase()}</h3>
                </div>
                <div className="ft-info">
                  <p>Page : </p>
                  <h3>
                    {idx + 1}/{quiz.length}
                  </h3>
                </div>
                <div className="ft-info">
                  <p>Topic : </p>
                  <h3> {quiz[idx]?.topic?.topic.toUpperCase()}</h3>
                </div>
                <div className="ft-info">
                  <p>Image Source : </p>
                  <h3>{quiz[idx]?.imageVideoSource}</h3>
                </div>
              </Grid>
            </>
          ) : (
            <h1 style={{ margin: "1rem auto" }}>NO DATA AVAILABLE</h1>
          )}
          {open && review ? (
            <EditModal
              setOpen={setOpen}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              quiz={quiz[idx]}
            />
          ) : null}
        </>
      )}
    </Grid>
  );
}

export default Quizes;
