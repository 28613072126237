import React, { useState } from "react";
import "../styles/curatorstats.css";
import { USER_SERVER } from "../Config.js";
import axios from "axios";
import { Button, Grid } from "@mui/material";
import makeRequest from "../helper/request.js";

export const CuratorStats = () => {
  const [date, setDate] = useState();
  const [stats, setStats] = useState({});

  const viewStates = async () => {
    // const date = datee.split("-");
    console.log(typeof date);
    const res = await makeRequest({
      method: 'get',
      endpoint: `/stats/:${date}`,
    })
    console.log(res.data);
    setStats(res.data);
  };
  return (
    <Grid sx={{ p: 5 }}>
      <Grid container sx={{ gap: 1, alignItems: "center" }}>
        <label>Select Date Range</label>
        <label>From</label>
        <input type="date" onChange={(e) => setDate(e.target.value)} />
        <label>To</label>
        <input type="date" />
        <Button onClick={viewStates}>View</Button>
      </Grid>

      <Grid sx={{ mt: 3 }}>
        <Grid container sx={{ justifyContent: "space-evenly" }}>
          <div className="card1">
            <p>Total Questions Created</p>
            <h2>{stats?.created}</h2>
          </div>
          <div className="card1">
            <p>Total Questions Reviewed</p>
            <h2>{stats?.reviewer}</h2>
          </div>
          <div className="card1">
            <p>Total Questions Edited</p>
            <h2>{stats?.editor}</h2>
          </div>
        </Grid>
        {/* <div className="card2-box">
          <div className="card2">
            <p>Options Edited</p>
            <h2>{stats?.editor}</h2>
          </div>
          <div className="card2">
            <p>Total Questions Edited</p>
            <h2>33</h2>
          </div>
          <div className="card2">
            <p>Amswers Updated</p>
            <h2>33</h2>
          </div>
        </div> */}
      </Grid>
    </Grid>
  );
};
