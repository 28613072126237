import axios from 'axios';
import { USER_SERVER } from './Config.js';
export const loginCall = async (userCredentials, dispatch) => {
  console.log(userCredentials);
  dispatch({ type: 'LOGIN_START' });
  try {
    const response = await axios.post(`${USER_SERVER}/signin`, userCredentials);

    console.log(response?.data);
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
  } catch (err) {
    console.log(err, 'err');
    alert(err);
    dispatch({ type: 'LOGIN_ERROR', payload: err });
  }
};

