import { React } from "react";
import { Routes, Route } from "react-router-dom";
import { Curator } from "./Curator";
import { CuratorStats } from "./CuratorStats";
import { CuratorCreateques } from "./CuratorCreateques";
import { CuratorReview } from "./CuratorReview";
import { Navbar } from "./Navbar";
import CuratorReviewed from "./CuratorReviewed";
import NotFound from "./NotFound";
function Dashboard() {
  const userObj = JSON.parse(localStorage.getItem("user"));
  const user= userObj?.user;

  return (
    <>
      <Navbar user={user} />
      <Routes>
        <Route exact path="/dashboard" element={<Curator user={user} />} />
        <Route exact path="/stats" element={<CuratorStats />} />
        <Route exact path="/createquestion" element={<CuratorCreateques />} />
        <Route exact path="/reviewed" element={<CuratorReviewed />} />
        <Route exact path="/edited" element={<CuratorReviewed />} />
        <Route exact path="/review" element={<CuratorReview />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Dashboard;
